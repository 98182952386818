import React from "react"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"

import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const Person = ({ data }) => (
  <Layout>
    <SearchEngineOptimizaion
      title={data.sanityPages.seo.seoTitle}
      description={data.sanityPages.seo.seoDescription}
    />

    <section className="aboutPage" style={{ marginTop: "150px" }}>
      <div className="titleWrapper">
        <h1>{data.sanityPages.pageTitle}</h1> <div className="spanLine"></div>
        {data.sanityPages._rawBody && (
          <BlockContent
            blocks={data.sanityPages._rawBody}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
            className="recipeBlockContent"
          />
        )}
      </div>

      <div className="aboutDescription">
        <GatsbyImage
          image={data.sanityAbout.coverImage.asset.gatsbyImageData}
          alt={data.sanityAbout.name}
          className="verticalImage"
          onLoad={() => {
            document
              .querySelector(".verticalImage")
              .classList.add("displayImage")
            infoBoxesHaveLoaded()
          }}
        />

        <p>{data.sanityAbout.imageText}</p>

        {data.sanityAbout._rawDescription && (
          <BlockContent
            blocks={data.sanityAbout._rawDescription}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
            className="aboutBlockContent"
          />
        )}
      </div>

      <div className="inboxWrapper">
        <ul>
          {data.sanityAbout.names.map(function (info, i) {
            return (
              <li key={i}>
                <div className="infoBoxHeader">
                  <h2>{info.title}</h2>
                  <GatsbyImage
                    image={info.icon.asset.gatsbyImageData}
                    alt={info.title}
                  />
                </div>

                <p>{info.description}</p>
              </li>
            )
          })}
        </ul>
      </div>

      <p className="velbekommen">God arbejdslyst!</p>
    </section>
  </Layout>
)

export default Person

export const query = graphql`
  query aboutQuery {
    sanityAbout {
      name
      _rawDescription
      imageText
      names {
        description
        title
        icon {
          asset {
            gatsbyImageData(
              backgroundColor: "#f9f9f9"
              placeholder: NONE
              width: 30
            )
          }
        }
      }
      coverImage {
        asset {
          gatsbyImageData(
            backgroundColor: "#f9f9f9"
            placeholder: NONE
            width: 530
          )
        }
      }
      profileImage {
        asset {
          gatsbyImageData(
            backgroundColor: "#f9f9f9"
            placeholder: NONE
            width: 400
          )
        }
      }
    }
    sanityPages(slug: { current: { eq: "om" } }) {
      pageTitle
      _rawBody
      seo {
        seoTitle
        seoDescription
      }
    }
  }
`

function infoBoxesHaveLoaded() {
  let infoWrapper = document.querySelector(".inboxWrapper")
  let infoWrapperHeight = infoWrapper.scrollHeight

  let infoBoxes = infoWrapper.querySelectorAll("li")

  infoBoxes.forEach(infobox => {
    infobox.style.minHeight = infoWrapperHeight + "px"
  })
}
